import React from 'react'
import { Link } from 'gatsby'
import SearchBox from '../searchbox/SearchBox';

import "../../scss/NavigationBar.scss"
import style from "./navigation.module.scss"

function NavigationBar(props) {
  const { siteNav } = props;

  const renderLinkElement = ({ linkName, linkPath }) => (
    <Link id='nav_link' tabIndex={0} to={linkPath} className={style["main-menu"]}>
      {linkName}
    </Link>
  )

  const renderMenuElement = (menuItems, isChild) => (
    <ul className={isChild ? style["child-menu"] : style["main-menu"]}>
      {menuItems.map((item, i) => {
        const hasChildren = item.link.children?.length > 0 || item.children?.length > 0
        const children = item.children
        return (
          <li key={i} className={`${isChild ? style["children"] : style["element"]} ${style["element-a"]}`}>
            {
              hasChildren ?
                <>
                  <Link id='nav_link' className={hasChildren && style["dropdown"]}>
                    {item.link.linkName}
                  </Link>
                  {renderMenuElement(children, hasChildren)}
                </> :
                renderLinkElement(item.link)
            }
          </li>
        )
      })}
    </ul>
  )

  const renderMenuMobile = (menuItems, isChild) => (
    <ul className={isChild ? style["resp-menu"] : style["resp-main"]}>
      {menuItems.map((item, i) => {
        const hasChildren = item.link.children?.length > 0 || item.children?.length > 0
        const children = item.children
        return (
          <li key={i} className={isChild ? style["resp-child"] : style["resp-elem"]}>
            {
              hasChildren ?
                <>
                  <Link id='nav_link' tabindex={-1} className={hasChildren && style["resp-drop"]}>
                    {item.link.linkName}
                  </Link>
                  {renderMenuMobile(children, hasChildren)}
                </> :
                renderLinkElement(item.link)
            }
          </li>
        )
      })}
    </ul>
  )
  const headerDealerInfo = props.dealerInfo.headerDealerInfo;

  return (
    <header id="header" className={style["header"]}>
      <div className="header site__section js-header">
        <section className="header__section site__subsection">
          <Link className="button button_type-icon header__logo icon_logo js-button nav-logo" to="/">
            <img className="nav-icon" src={props.dealerInfo.dealerLogo} alt={props.dealerInfo.dealerName} />
          </Link>
          <Link className="lotus-font-secondary-title button button_type-icon header__logo js-button text_logo" to="/">
            <span className="lotus-font-secondary-title">{props.dealerInfo.dealerName.substring(0, props.dealerInfo.dealerName.indexOf(" "))}</span>
            {props.dealerInfo.dealerName.substring(props.dealerInfo.dealerName.indexOf(" "), props.dealerInfo.dealerName.length)}
          </Link>
        </section>
        <section className="header__section site__subsection">
          <menu className="menu js-menu">
            <div className="menu__wrapper">
              {renderMenuElement(props.siteNav.menu)}
            </div>
          </menu>
        </section>
        <a onClick={() => toggleMobile()} className="burger header__explore-menu js-header-explore-button">
          <a class="burger header__explore-menu js-header-explore-button">
            <ul class="burger__buns">
              <div class="menu-grid">
                <li class="burger__bun"></li>
                <li class="burger__bun"></li>
                <li class="burger__bun"></li>
              </div>
              <div class="menu-grid">
                <li class="burger__bun"></li>
                <li class="burger__bun"></li>
                <li class="burger__bun"></li>
              </div>
              <div class="menu-grid">
                <li class="burger__bun"></li>
                <li class="burger__bun"></li>
                <li class="burger__bun"></li>
              </div>
            </ul>
          </a>
        </a>
        
        {headerDealerInfo? (  
        <section className="header__section site__subsection mobile_hidden js-menu">
          <a href={'tel:' + props.dealerInfo.salesNumber} className="icon-spoiler submenu__explore-map js-icon-spoiler" data-target="js-mobile-map" data-id="map-spoiler" data-department="sales">
              <svg version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 36 36">
                  <path className="st0" d="M35.6,30.2c-2,4.9-6.8,5.7-8.4,5.7c-0.5,0-7.6,0.4-17.8-9.3C1.2,18.8,0.2,10.4,0.1,8.8C0,7.2,0.4,3.3,5.8,0.4
                              c0.7-0.4,2-0.5,2.3-0.2c0.2,0.2,4.7,7.6,4.8,7.8c0.1,0.2,0.2,0.5,0.2,0.7c0,0.3-0.2,0.8-0.7,1.3c-0.5,0.5-1,1-1.6,1.4
                              c-0.6,0.4-1.1,0.9-1.6,1.3c-0.5,0.5-0.7,0.9-0.7,1.2c0,0.8,0.7,3.6,5.6,8c4.9,4.3,7.3,5.6,7.5,5.6c0.2,0.1,0.4,0.1,0.6,0.1
                              c0.3,0,0.7-0.2,1.2-0.7c0.5-0.5,2.1-2.7,2.6-3.1c0.5-0.5,0.9-0.7,1.3-0.7c0.2,0,0.5,0.1,0.7,0.2c0.2,0.1,7.7,4.5,7.8,4.6
                              C36.1,28.3,35.9,29.5,35.6,30.2"></path>
              </svg>
              <span className="linkSecondary" >{props.dealerInfo.salesNumber}</span>
          </a>
          <span className="icon-split"></span>
          <Link id='nav_link' to={props.dealerInfo.directions} className="icon-spoiler directions submenu__explore-map js-icon-spoiler" data-target="js-mobile-map" data-id="map-spoiler">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 30 38"><g><g><path fill="#000" d="M15.038 22.155a7.192 7.192 0 0 1-7.192-7.193 7.192 7.192 0 0 1 7.192-7.192 7.192 7.192 0 0 1 7.193 7.192 7.192 7.192 0 0 1-7.193 7.193zM14.511.009C7.136.27 1.009 6.028.3 13.38a14.925 14.925 0 0 0 .297 4.723c.228 1.021.57 2.012.997 2.941 1.484 3.52 4.917 9.408 12.619 15.815a1.286 1.286 0 0 0 1.644 0C23.56 30.46 26.992 24.57 28.484 21.035c.434-.929.769-1.912.997-2.94a14.915 14.915 0 0 0 .365-3.276c0-8.365-6.918-15.107-15.335-14.81z"></path></g></g></svg>			        <div className="icon-spoiler__caption">
                  <div className="icon-spoiler__title">directions</div>
              </div>
          </Link>
        </section>)
        :
        (<section className="header__section site__subsection mobile_hidden js-menu">
          <a href={'tel:' + props.dealerInfo.salesNumber} className="icon-spoiler submenu__explore-map js-icon-spoiler headerDealerInfo" data-target="js-mobile-map" data-id="map-spoiler" data-department="sales">
              <svg version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 36 36">
                  <path className="st0" d="M35.6,30.2c-2,4.9-6.8,5.7-8.4,5.7c-0.5,0-7.6,0.4-17.8-9.3C1.2,18.8,0.2,10.4,0.1,8.8C0,7.2,0.4,3.3,5.8,0.4
                              c0.7-0.4,2-0.5,2.3-0.2c0.2,0.2,4.7,7.6,4.8,7.8c0.1,0.2,0.2,0.5,0.2,0.7c0,0.3-0.2,0.8-0.7,1.3c-0.5,0.5-1,1-1.6,1.4
                              c-0.6,0.4-1.1,0.9-1.6,1.3c-0.5,0.5-0.7,0.9-0.7,1.2c0,0.8,0.7,3.6,5.6,8c4.9,4.3,7.3,5.6,7.5,5.6c0.2,0.1,0.4,0.1,0.6,0.1
                              c0.3,0,0.7-0.2,1.2-0.7c0.5-0.5,2.1-2.7,2.6-3.1c0.5-0.5,0.9-0.7,1.3-0.7c0.2,0,0.5,0.1,0.7,0.2c0.2,0.1,7.7,4.5,7.8,4.6
                              C36.1,28.3,35.9,29.5,35.6,30.2"></path>
              </svg>
              <span className="linkSecondary" >{props.dealerInfo.salesNumber}</span>
          </a>
        </section>)
      }
      </div>
      <section className='nav-mobile'>
        <div id="mobileDropDown" className="nav-cont">
          <div className="nav-overflow">
            {renderMenuMobile(props.siteNav.menu)}
          </div>
        </div>
      </section>
    </header>
  )
}

function toggleDrop(id) {
  const div = document.getElementById(id)
  div.classList.toggle('showDrop')
}

function toggleMobile() {
  document.getElementById("mobileDropDown").classList.toggle('showMobile');
  document.getElementsByClassName("header__explore-menu")[0].classList.toggle('state_toggled');
}

export default NavigationBar

